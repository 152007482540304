<script setup lang="ts">
import { onMounted } from "vue";
const route = useRoute();
//Get query params from router

onMounted(() => {
  //Redirect to Tesla Login
  localStorage.setItem("redirect", route.query?.redirect || "/console");
  const state = Math.random().toString(36).substring(2, 12);
  localStorage.setItem("state", state);
  document.location = `https://auth.tesla.com/oauth2/v3/authorize?response_type=code&client_id=85c15e671b9a-427e-94eb-c7b5fe64eec1&redirect_uri=${location.origin}/redirect&scope=openid offline_access user_data vehicle_device_data vehicle_location vehicle_cmds vehicle_charging_cmds energy_device_data energy_cmds&state=${state}`;
});

definePageMeta({
  layout: "auth",
});

useSeoMeta({
  title: "Login",
});
</script>

<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <ULandingHero
    title="Redirecting you to auth.tesla.com"
    description="Teslemetry uses your Tesla account for authentication."
  />
</template>
